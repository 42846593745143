import React, { useEffect, useState, useMemo } from 'react'
import {
  Layout,
  Table,
  Text,
  SelectDateModal,
  FinanceResumeCard,
  MobileList,
  BarbersCommissionReportCard,
  ClearedComissionModal,
  Avatar,
  PieChart,
  Icon,
  TextInput,
  Box,
  Button,
  WorthBarberModal,
} from 'components'
import { useHistory } from 'react-router-dom'
import { settingsMobile } from './settings'
import { Center, Flex, Spinner, useToast, useTheme, Select } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useCommissionStore } from 'services/stores'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { parseISO, format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'
import { financesStore } from 'services/stores/finances'

import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { BARBER_EXTRACT } from 'services/api/endpoints'
import { UnitysModal } from 'components/organisms/Unitys'
import { useBarbershop } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const BarbersMonthReportScreen = (props) => {
  const history = useHistory()
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [clearedModalOpen, setClearedModalOpen] = useState(false)
  const [worthModalOpen, setWorthModalOpen] = useState(false)
  const { setSelectedCommission } = useCommissionStore()
  const [type, setType] = useState()
  const { setBarbersMonthExtract, unity, setUnity } = financesStore()
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const { exportComissionReport, getAllAndInactiveEmployees } = useBarbershop()
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))

  const cellColors = [...theme.colors.pieChart]
  //
  useEffect(() => {
    setUnity(userData)
  }, [userData])

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    refetch: refetchBarbers,
  } = useQuery(['get-all-employees'], () => getAllAndInactiveEmployees({ barbershop_id: userData?.id }))
  const columns = (userData) => {
    const arr = [
      {
        width: 200,
        key: 'barber',
        label: 'COLLABORATOR',
        render: (item, index) => {
          return (
            <Flex flexDir="row" align="center">
              <Avatar
                size="medium"
                src={
                  employees?.find((e) => e?.id === item?.barber_id || e?.id === item?.employee_id)?.profile_image_url
                }
                mr={theme.pxToRem(16)}
              />
              <Box
                mr={theme.pxToRem(8)}
                h={theme.pxToRem(8)}
                w={theme.pxToRem(8)}
                bgColor={cellColors[index % cellColors?.length]}
                borderRadius={100}
              />
              <Box>
                <Text>
                  {employees?.find((e) => e?.id === item?.barber_id || e?.id === item?.employee_id)?.name ||
                    'Venda sem colaborador'}
                </Text>
                <Text fontSize={12}>{item?.appointments_length || 0} agendamentos</Text>
              </Box>
            </Flex>
          )
        },
      },
      {
        key: 'total_sales',
        label: 'TOTAL_SALES_TABLE',
        render: (item) => <Text>{formatPrice(item?.total_sales)}</Text>,
      },
      {
        key: 'sales_services',
        label: 'BARBER_COMMISSION_SERVICE',
        render: (item) => <Text>{formatPrice(item?.services_comission)}</Text>,
      },
      {
        key: 'sales_products',
        label: 'BARBER_COMMISSION_PRODUCT',
        render: (item) => <Text>{formatPrice(item?.products_comission)}</Text>,
      },
      {
        key: 'total_comission_signature',
        label: 'COMISSÃO ASSINATURA',
        render: (item) => <Text>{formatPrice(item?.comission_signature)}</Text>,
      },
      {
        key: 'extra',
        label: 'TOTAL EM EXTRAS',
        render: (item) => <Text>{formatPrice(item?.extra_value)}</Text>,
      },
      {
        width: 100,
        key: 'shopping',
        label: 'COMPRAS DE PRODUTO',
        render: (item) => <Text color="danger">{formatPrice(-item?.shopping_value)}</Text>,
      },
      {
        key: 'worth',
        label: 'TOTAL EM VALES',
        render: (item) => <Text color="danger">{formatPrice(-item?.worth_value)}</Text>,
      },
      {
        key: 'total_comission_paid',
        label: 'COMISSÕES PAGAS',
        render: (item) => <Text>{formatPrice(item?.total_comission_paid)}</Text>,
      },
      {
        key: 'total_comission_pending',
        label: 'COMISSÕES PENDENTES',
        render: (item) => <Text>{formatPrice(item?.total_comission_pending)}</Text>,
      },
      // {
      //   key: 'percentage',
      //   label: 'BARBER_COMMISSION_SERVICE_TABLE',
      //   render: (item) => <Text>{item?.barber?.percentage || 0}%</Text>,
      // },
      // {
      //   key: 'products_percentage',
      //   label: 'BARBER_COMMISSION_PRODUCT_TABLE',
      //   render: (item) => <Text>{item?.barber?.products_percentage || 0}%</Text>,
      // },
      // {
      //   key: 'total_comission_signature',
      //   label: 'TOTAL DE COMISSÕES NORMAIS',
      //   render: (item) => (
      //     <Text>{formatPrice(item?.commom_services_comission)}</Text>
      //   ),
      // },
    ]

    if (userData?.signature_club_enable) {
      arr.push({
        key: 'points',
        label: 'TOTAL DE PONTOS',
        render: (item) => <Text>{item?.points}</Text>,
      })
    }

    return arr
  }

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const types = [
    {
      name: 'Outros colaboradores',
      value: 'collaborator',
    },
    {
      name: 'Barbeiros',
      value: 'barber',
    },
  ]

  async function getBarberExtract({ startDate, endDate, type }) {
    const params = {
      id: userData?.barbershop?.id || userData?.id,
      start_date: startDate,
      end_date: endDate,
      type: type ? types.find((i) => i.name === type).value : null,
    }
    const res = await api
      .post(BARBER_EXTRACT(params))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading, refetch, isError, isRefetching } = useQuery(
    ['get-barber-extract', unity],
    () =>
      getBarberExtract({
        startDate,
        endDate,
        type,
      }),
    {
      onSuccess: (value) => {
        refetchBarbers()
        setBarbersMonthExtract(value)
      },
      onError: (error) => {
        toast({
          title: t('LOAD_COMMISSIONS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  const mutate = useMutation(
    ['get-barber-excel'],
    (params) => exportComissionReport({ params: params, id: params.id }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
      },
      retry: false,
    }
  )

  const dataPie = () => {
    let label = data?.comissions_info?.data?.map(
      (item) =>
        employees?.find((e) => e?.id === item?.barber_id || e?.id === item?.employee_id)?.name ||
        'Venda sem colaborador'
    )
    let d = data?.comissions_info?.data?.map((item) => item?.appointments_length)

    const values = {
      label,
      data: d,
    }
    return values
  }

  const onSelectCommission = (comission) => {
    setSelectedCommission({ comission, startDate, endDate })
    history.push(`/detalhes-comissao`)
  }

  const handleExport = () => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      id: userData?.barbershop?.id || userData?.id,
      type: type ? types.find((i) => i.name === type).value : null,
      report: 'general',
    }
    mutate.mutate(params)
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('COLLABORATORS_REPORT')}
      widthTitle="100%"
      secondActionClick={() => setUnityModalOpen(true)}
      secondActionText={t('CHANGE_UNITY')}
      iconSecondActionText={'Shop'}
      subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      iconActionText="Money"
      headerActionText={t('CLEARED_COMISSION')}
      headerActionClick={() => setClearedModalOpen(true)}
      overflowX="auto"
      thirtyClick={{
        icon: 'Excel',
        onClick: handleExport,
        isLoading: mutate.isLoading,
        text: 'Exportar em excel',
      }}
    >
      <>
        {(!data || isLoading || isLoadingEmployees || isRefetching) && !isError ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : isDesktop ? (
          <Flex w="100%" flexDir="column">
            <Flex w="100%" flexDir="row" alignItems="stretch" justifyContent="space-between" pb={theme.pxToRem(12)}>
              <Flex h={theme.pxToRem(210)} w="79.4%" flexDir="column" justifyContent="space-between" flexWrap="wrap">
                <FinanceResumeCard
                  width="30%"
                  label={t('SALES')}
                  description={t('TOTAL')}
                  value={data?.appointments_total_value || 0}
                  color={'success'}
                  info="disabled"
                  infoText="Valor total bruto de agendamentos do período - descontos"
                />
                <Flex w="30%">
                  <FinanceResumeCard
                    width="50%"
                    notCurrency
                    label={t('SELLS')}
                    description={t('TOTAL')}
                    value={data?.total_appointments || 0}
                    color={'white'}
                  />
                  <FinanceResumeCard
                    width="50%"
                    notCurrency
                    label={t('SELLS')}
                    description={t('FINISHED')}
                    value={data?.total_finished || 0}
                    color={'white'}
                    last
                  />
                </Flex>

                {/* {settings( data)?.map((item) => (
                  <FinanceResumeCard
                    width="30%"
                    notCurrency={item?.notCurrency}
                    label={t(item?.label)}
                    description={t(item?.description)}
                    value={item?.value}
                    color={item?.color}
                    info={item?.info}
                    infoText={item?.infoText}
                  /> 
                ))}  */}
                <Flex w="30" mr="16px">
                  <FinanceResumeCard
                    width="50%"
                    label="Total"
                    description="Vendas serviços"
                    value={data?.appointments_total_services || 0}
                    info="disabled"
                    infoText="Valor total de vendas referentes a serviços"
                    color={'success'}
                  />
                  <FinanceResumeCard
                    width="50%"
                    label="Comissões"
                    description="Assinaturas"
                    value={data?.comission_signatures || 0}
                    color={'success'}
                    last
                  />
                </Flex>
                <Flex w="30%" mr="16px">
                  <FinanceResumeCard
                    width="50%"
                    label="Total"
                    description="Vendas produto"
                    value={data?.appointments_total_products || 0}
                    info="disabled"
                    infoText="Valor total de vendas referentes a produtos"
                    color={'success'}
                  />
                  <FinanceResumeCard
                    width="50%"
                    label="Comissões"
                    description="Normais"
                    value={data?.commissions_normal || 0}
                    color={'success'}
                    last
                  />
                </Flex>
                <Flex w="30%" mr="16px">
                  <FinanceResumeCard
                    width="50%"
                    label="Comissões"
                    description="Pagas"
                    value={data?.appointments_total_comissions_paid || 0}
                    color={'success'}
                  />
                  <FinanceResumeCard
                    width="50%"
                    label="Comissões"
                    description="Pendentes"
                    value={(data?.appointments_total_comissions_pending || 0) - (data?.worht_total_value || 0)}
                    color={'danger'}
                    last
                  />
                </Flex>

                <Flex w="30%" mr="16px">
                  <FinanceResumeCard
                    width="50%"
                    label="COMMISSIONS"
                    description="TOTAL"
                    value={data?.appointments_total_comissions || 0}
                    color={'primary'}
                  />
                  <FinanceResumeCard
                    width="50%"
                    label="Vales"
                    description="Pagos"
                    value={data?.worht_total_value || 0}
                    color={'primary'}
                    last
                  />
                </Flex>
                <Flex h={theme.pxToRem(210)} w="31%" align="center" justify="center">
                  {data?.comissions_info?.length !== 0 ? (
                    <PieChart title={'COMISSIONS_REPORT'} h="100%" w="100%" data={dataPie()} />
                  ) : (
                    <Flex flexDir="column" align="center">
                      <Icon name="Chart" size={40} />
                      <Text align="center">{t('NO_DATA_RESUME')}</Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex mb={theme.pxToRem(16)}>
              <Flex w="35%" mr="20px">
                <TextInput
                  noBorder
                  type="date"
                  name="start_date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  label={t('START')}
                  padding="4%"
                  width={'90%'}
                />
                <TextInput
                  noBorder
                  type="date"
                  name="end_date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  label={t('END')}
                  // ml="10%"
                  padding="5%"
                  width={'90%'}
                />
                <Button
                  loading={isRefetching}
                  icon="Search"
                  size="small"
                  iconColor="black"
                  // ml="12px"
                  mt="26px"
                  onClick={() => {
                    refetch()
                  }}
                >
                  Pesquisar
                </Button>
              </Flex>
              <Flex
                mr={theme.pxToRem(16)}
                flexDir="row"
                borderRadius={theme.pxToRem(8)}
                bg="cardBackground"
                h={theme.pxToRem(42)}
                align="center"
                justify="center"
                mt="26px"
              >
                <Text fontSize={14} kind="medium" ml={theme.pxToRem(8)} mr={theme.pxToRem(4)} color="textGrey">
                  {t('COLLABORATOR_TYPE')}:
                </Text>

                <Select
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                  placeholder={t('ALL')}
                  w={theme.pxToRem(140)}
                  fontFamily="Montserrat Bold"
                  fontSize={14}
                  textColor="textLight"
                  iconColor="primary"
                  borderColor="transparent"
                  errorBorderColor="baseRed"
                  focusBorderColor="transparent"
                  variant="unstyled"
                  marginBottom={theme.pxToRem(2)}
                >
                  {!!types &&
                    types.map((item, index) => (
                      <option
                        style={{
                          backgroundColor: theme.colors.menuBackground,
                          fontFamily: 'Montserrat Medium',
                        }}
                        key={`barber_${item?.id}`}
                      >
                        {item?.name}
                      </option>
                    ))}
                </Select>
              </Flex>
              <Button
                mr={theme.pxToRem(16)}
                icon="Money"
                iconColor="black"
                width={theme.pxToRem(140)}
                size="small"
                onClick={() => setWorthModalOpen(true)}
                mt="26px"
              >
                Adicionar lançamento para o barbeiro
              </Button>
              {/* <Button icon='Shampoo' iconColor='black' width={theme.pxToRem(140)} size='small' onClick={() => setProductModalOpen(true)}>
                Adicionar venda de produto ao barbeiro
              </Button> */}
            </Flex>
            <Table
              loading={isLoading}
              data={
                data?.comissions_info?.data?.sort((a, b) => {
                  return a?.total_sales > b?.total_sales ? -1 : a?.total_sales < b?.total_sales ? 1 : 0
                }) || []
              }
              columns={columns(userData)}
              isSelectableRow
              onSelect={(item) => onSelectCommission(item)}
            />
          </Flex>
        ) : (
          <Flex flexDirection="column" width="full">
            <Flex w="100%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(348)}>
              {settingsMobile(data)?.map((item) => (
                <FinanceResumeCard
                  wMobile="50%"
                  notCurrency={item?.notCurrency}
                  label={t(item?.label)}
                  description={t(item?.description)}
                  value={item?.value}
                  color={item?.color}
                  last={item?.last}
                />
              ))}
            </Flex>

            <FinanceResumeCard
              full
              label={t('SALES')}
              description={t('TOTAL')}
              value={data?.appointments_total_value}
              color={'success'}
            />

            <MobileList
              loading={isLoading}
              data={data?.comissions_info?.data || []}
              renderItem={({ item, index }) => (
                <BarbersCommissionReportCard item={item} index={index} onSelect={(item) => onSelectCommission(item)} />
              )}
            />
          </Flex>
        )}
      </>

      <SelectDateModal
        onClose={() => setDateModalOpen(false)}
        onlyMonth
        isModalOpen={dateModalOpen}
        selectedDate={selectedDate}
        onSelectDate={(date) => {
          setSelectedDate(parseISO(date))
          setDateModalOpen(false)
        }}
      />
      {unityModalOpen && (
        <UnitysModal
          isModalOpen={unityModalOpen}
          onClose={() => setUnityModalOpen(false)}
          selected={unity}
          setSelected={setUnity}
        />
      )}
      {clearedModalOpen && (
        <ClearedComissionModal
          isModalOpen={clearedModalOpen}
          onClose={() => setClearedModalOpen(false)}
          refetch={refetch}
        />
      )}
      {worthModalOpen && (
        <WorthBarberModal isModalOpen={worthModalOpen} onClose={() => setWorthModalOpen(false)} refetch={refetch} />
      )}
    </Layout>
  )
}

BarbersMonthReportScreen.path = '/relatorio-barbeiros'
BarbersMonthReportScreen.title = 'Relatório Mensal - Barbeiros'
BarbersMonthReportScreen.secured = true

import React, { useEffect, useState, version } from 'react'
import { TextInput, Button, Text, Table } from 'components'
import { Flex, useToast, useTheme } from '@chakra-ui/react'
import { userManagementStore } from 'services/stores'
import { Layout } from '../Components'
import { unmaskPhone } from 'utils/phone-formater'
import { format, parseISO } from 'date-fns'
import { formatPrice } from 'utils'
import { RefundConfirmationModal } from 'components/organisms/RefundConfirmationModal'
import { ChangeDueDateModal } from '../Modals/ChangeDueDate'

export const UsersManagement = () => {
  const theme = useTheme()
  const toast = useToast()
  const {
    loading,
    onGetUserData,
    userData,
    cleanUserData,
    onUpdatePassword,
    onInactivateUser,
    onUpdateSignatureActive,
    onUpdateNfActive,
    onUpdatBarbershopInfo,
    onUpdateClientSignature,
    onUpdateClientSignatureDueDate,
  } = userManagementStore()
  const [query, setQuery] = useState('')
  const [type, setType] = useState('e-mail')
  const [selected, setSelected] = useState(null)
  const [password, setPassword] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState(null)

  const onChangeUser = (text) => {
    const unmasked = text.replace(/[^a-zA-Z0-9@._]/g, '')
    if (!isNaN(parseFloat(unmasked)) && isFinite(unmasked)) {
      setType('phone')
      setQuery(text)
      return
    }
    setType('e-mail')
    let unmaskedEmail = text.replace(/[^a-zA-Z0-9@._-]/g, '')
    if (type === 'phone') {
      unmaskedEmail = text.replace(/[^a-zA-Z0-9@._]/g, '')
    }
    setQuery(unmaskedEmail)
  }

  const onSuccess = () => {
    cleanUserData()
    onGetUserData(type === 'phone' ? unmaskPhone(query) : query, toast)
    setSelected(null)
    setSubscriptionId(null)
    setModalOpen(false)
  }

  const onInactivate = async () => {
    const payload = {
      id: selected?.id,
      status: !selected?.inactive,
    }
    const resp = await onInactivateUser(payload, toast)
    if (!resp.error || !resp) {
      onSuccess()
    }
  }

  const onChangePassword = async () => {
    const payload = {
      user_id: selected?.id,
      password,
    }
    const resp = await onUpdatePassword(payload, toast)
    if (!resp?.error) {
      setPassword('')
    }
  }

  const onChangeSignature = async (barbershop_id, status) => {
    const resp = await onUpdateSignatureActive({ barbershop_id, status }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onChangeNF = async (barbershop_id, status) => {
    const resp = await onUpdateNfActive({ barbershop_id, status }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onChangeInfo = async (barbershop_id, data) => {
    const resp = await onUpdatBarbershopInfo({ barbershop_id, data }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onChangeClientSignature = async (signature_id, data) => {
    const resp = await onUpdateClientSignature({ signature_id, data }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onChangeDueDate = async (signature_id, data) => {
    const resp = await onUpdateClientSignatureDueDate({ signature_id, data }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  useEffect(() => {
    setPassword('')
  }, [selected, userData])

  useEffect(() => {
    cleanUserData()
  }, [])

  const columns = [
    {
      key: 'id',
      label: 'ID DO PLANO',
      render: (item) => <Text>{item?.id}</Text>,
    },
    {
      key: 'name',
      label: 'NOME',
      render: (item) => <Text>{item?.name}</Text>,
    },
    {
      key: 'value',
      label: 'VALOR',
      render: (item) => <Text>{formatPrice(item?.value)}</Text>,
    },
  ]

  return (
    <Layout title="Gerenciamento de usuários">
      <Flex flexDir="row">
        <Flex flexDir="column" mr={theme.pxToRem(16)}>
          <Flex flexDir="row" mb={theme.pxToRem(12)}>
            <TextInput
              type="inputMask"
              mask={type === 'phone' ? '** *****-****' : null}
              alwaysShowMask={false}
              maskChar={null}
              noBorder
              name="user"
              label="Buscar usuário"
              value={query}
              w={theme.pxToRem(370)}
              onChange={(event) => onChangeUser(event?.target?.value)}
            />
            <Button
              isLoading={loading === 'getUser'}
              mt={theme.pxToRem(26)}
              ml={theme.pxToRem(8)}
              w={theme.pxToRem(60)}
              onClick={() => {
                onGetUserData(type === 'phone' ? unmaskPhone(query) : query, toast)
                setSelected(null)
              }}
              icon="Search"
              iconColor={'cardBackground'}
            />
          </Flex>
          {!!userData && <Text>Usuários encontrados:</Text>}
          {userData?.map((u) => {
            return (
              <Flex
                onClick={() => setSelected(u)}
                flexDir={'column'}
                mt={4}
                p={4}
                borderColor={selected === u ? 'primary' : 'border'}
                borderWidth={1}
                borderRadius={8}
                cursor={'pointer'}
              >
                <Text>Telefone do usário: {u.phone}</Text>
                <Text>E-mail do usário: {u.email}</Text>
                <Text>Tipo de usuário: {u.type}</Text>
                <Text color={u.inactive ? 'danger' : 'success'}>
                  Status do cadastro: {u.inactive ? 'Desativado' : 'Ativo'}
                </Text>
                <Text>Data de cadastro: {format(parseISO(u.createdAt), 'dd/MM/yyyy')}</Text>
              </Flex>
            )
          })}
        </Flex>
        {!!selected && (
          <Flex
            flexDir={'column'}
            mt={4}
            p={4}
            borderColor={'border'}
            borderWidth={1}
            borderRadius={8}
            cursor={'pointer'}
            w={'60vw'}
            pb={16}
          >
            <Flex w="100%" justify={'space-between'}>
              <Flex flexDir={'column'}>
                <Text>ID do usário (user_id): {selected.id}</Text>
                {selected?.client?.[0]?.id && <Text>ID do cliente (client_id): {selected?.client?.[0]?.id}</Text>}
                <Text>Telefone do usário: {selected.phone}</Text>
                <Text>E-mail do usário: {selected.email}</Text>
                <Text>Tipo de usuário: {selected.type}</Text>
              </Flex>
              <Flex flexDir={'column'}>
                <Text mt={2} mr={2} color={selected.inactive ? 'danger' : 'success'}>
                  Status do cadastro: {selected.inactive ? 'Desativado' : 'Ativo'}
                </Text>
                <Button kind={selected.inactive ? 'success' : 'danger'} onClick={onInactivate} size="x-small">
                  {selected.inactive ? 'Ativar' : 'Desativar'} cadastro
                </Button>
              </Flex>
            </Flex>

            <Flex mt={2} mb={6} align="flex-end">
              <TextInput
                label="Alterar senha:"
                name="password"
                value={password}
                w="100%"
                mr="2%"
                onChange={(event) => setPassword(event?.target?.value)}
              />
              {/* <TextInput
                label="ID da barbearia"
                name="barbershop_id"
                value={password}
                w="25%"
                mr="4%"
                onChange={(event) => setPassword(event?.target?.value)}
              /> */}
              <Button onClick={onChangePassword} mb={-6} size="medium">
                Salvar
              </Button>
            </Flex>
            {selected?.client?.[0]?.subscriptions?.length > 0 && (
              <>
                {selected?.client?.[0]?.subscriptions.map((signature) => {
                  return (
                    <Flex
                      flexDir="row"
                      p={4}
                      justify="space-between"
                      align="center"
                      borderRadius={8}
                      mt={2}
                      borderColor={'border'}
                      borderWidth={1}
                      w="100%"
                    >
                      <Flex flexDir="column">
                        {/* <Text>{b?.name}</Text> */}
                        <Text fontSize={12}>
                          ID da assinatura: {signature?.id} | Barbearia: {signature?.barbershop?.name}
                        </Text>
                        <Text fontSize={12}>
                          Plano: {signature?.plan?.name} | Status: {signature?.inactive ? 'Desativada' : 'Ativa'}
                        </Text>
                        <Text fontSize={12}>
                          Vencimento:{' '}
                          {signature?.due_simple_date
                            ? format(parseISO(signature?.due_simple_date), 'dd/MM/yyyy')
                            : 'Não informado'}{' '}
                          | Modificação: {signature?.change_type || 'Nenhuma'}
                        </Text>
                      </Flex>
                      <Flex flexDir="column">
                        <Button
                          kind={signature?.inactive ? 'success' : 'danger'}
                          onClick={() => onChangeClientSignature(signature?.id, { inactive: !signature?.inactive })}
                          size="x-small-auto"
                        >
                          {signature?.inactive ? 'Ativar' : 'Desativar'} assinatura do clube
                        </Button>
                        <Button
                          kind="primary"
                          onClick={() => {
                            setSubscriptionId(signature.id)
                            setModalOpen(true)
                          }}
                          size="x-small-auto"
                          mt="12px"
                        >
                          Alterar data de vencimento
                        </Button>
                      </Flex>
                    </Flex>
                  )
                })}
              </>
            )}
            {selected?.barbershop?.length > 0 && (
              <>
                <Text mt={4}>Barbearias (unidades):</Text>
                {selected?.barbershop?.map((b, index) => {
                  return (
                    <Flex
                      flexDir="column"
                      p={4}
                      align="flex-start"
                      borderRadius={8}
                      mt={2}
                      borderColor={'border'}
                      borderWidth={1}
                    >
                      <Flex flexDir="column">
                        <Text>{b?.name}</Text>
                        <Text fontSize={12}>
                          ID da barbearia: {b?.id} | Cadastro: {b?.inactive ? 'Desativado' : 'Ativo'} | Status:{' '}
                          {b?.status}
                        </Text>
                      </Flex>
                      <Flex mt={4}>
                        <Button
                          kind={b?.signature_club_enable ? 'danger' : 'success'}
                          onClick={() => onChangeSignature(b?.id, !b?.signature_club_enable)}
                          size="x-small-auto"
                        >
                          {b?.signature_club_enable ? 'Desativar' : 'Ativar'} clube de assinatura
                        </Button>
                        <Button
                          ml={1}
                          kind={b?.nf_module_active ? 'danger' : 'success'}
                          onClick={() => onChangeNF(b?.id, !b?.nf_module_active)}
                          size="x-small-auto"
                        >
                          {b?.nf_module_active ? 'Desativar' : 'Ativar'} nota fiscal
                        </Button>
                        <Button
                          ml={1}
                          kind={b?.push_notification ? 'danger' : 'success'}
                          onClick={() => onChangeInfo(b?.id, { push_notification: !b?.push_notification })}
                          size="x-small-auto"
                        >
                          {b?.push_notification ? 'Desativar' : 'Ativar'} push notification
                        </Button>
                      </Flex>
                      <Flex mt={4} flexDir="column" w="100%">
                        <Flex align="flex-end" w="100%" justify="space-between" pb={6}>
                          <TextInput
                            label="ID do OneSignal:"
                            value={b?.one_signal_id}
                            w="50%"
                            onChange={(event) => {
                              const barbershopArray = selected?.barbershop
                              barbershopArray[index] = {
                                ...barbershopArray[index],
                                one_signal_id: event?.target?.value,
                              }
                              setSelected({
                                ...selected,
                                barbershop: barbershopArray,
                              })
                            }}
                          />
                          <TextInput
                            label="Versão API"
                            value={b?.version}
                            w="15%"
                            onChange={(event) => {
                              const barbershopArray = selected?.barbershop
                              barbershopArray[index] = { ...barbershopArray[index], version: event?.target?.value }
                              setSelected({
                                ...selected,
                                barbershop: barbershopArray,
                              })
                            }}
                          />
                          <TextInput
                            label="Status"
                            value={b?.status}
                            w="20%"
                            onChange={(event) => {
                              const barbershopArray = selected?.barbershop
                              barbershopArray[index] = { ...barbershopArray[index], status: event?.target?.value }
                              setSelected({
                                ...selected,
                                barbershop: barbershopArray,
                              })
                            }}
                          />
                          <Button
                            w={'12%'}
                            mb={-6}
                            onClick={() => {
                              onChangeInfo(b?.id, {
                                one_signal_id: b?.one_signal_id,
                                version: b?.version,
                                status: b?.status,
                              })
                            }}
                            size="medium"
                          >
                            Salvar
                          </Button>
                        </Flex>
                      </Flex>
                      <Flex mt="12px" w="100%" align="center" justify="center">
                        <TextInput
                          label="REST API Key do OneSignal:"
                          value={b?.notification_rest_key}
                          w="86%"
                          onChange={(event) => {
                            const barbershopArray = selected?.barbershop
                            barbershopArray[index] = {
                              ...barbershopArray[index],
                              notification_rest_key: event?.target?.value,
                            }
                            setSelected({
                              ...selected,
                              barbershop: barbershopArray,
                            })
                          }}
                        />
                        <Button
                          w={'12%'}
                          mt="24px"
                          ml="12px"
                          onClick={() => {
                            onChangeInfo(b?.id, {
                              notification_rest_key: b?.notification_rest_key,
                            })
                          }}
                          size="medium"
                        >
                          Salvar
                        </Button>
                      </Flex>

                      <Flex mt="32px" flexDir="column" w="100%">
                        <Flex align="flex-end" w="100%" justify="space-between" pb={6}>
                          <TextInput
                            label="Iugu Client ID:"
                            value={b?.iugu_client_id}
                            w="43%"
                            onChange={(event) => {
                              const barbershopArray = selected?.barbershop
                              barbershopArray[index] = {
                                ...barbershopArray[index],
                                iugu_client_id: event?.target?.value,
                              }
                              setSelected({
                                ...selected,
                                barbershop: barbershopArray,
                              })
                            }}
                          />
                          <TextInput
                            label="Iugu Subscription ID"
                            value={b?.iugu_subscription_id}
                            w="43%"
                            onChange={(event) => {
                              const barbershopArray = selected?.barbershop
                              barbershopArray[index] = {
                                ...barbershopArray[index],
                                iugu_subscription_id: event?.target?.value,
                              }
                              setSelected({
                                ...selected,
                                barbershop: barbershopArray,
                              })
                            }}
                          />
                          <Button
                            w={'12%'}
                            mb={-6}
                            onClick={() =>
                              onChangeInfo(b?.id, {
                                iugu_client_id: b?.iugu_client_id,
                                iugu_subscription_id: b?.iugu_subscription_id,
                              })
                            }
                            size="medium"
                          >
                            Salvar
                          </Button>
                        </Flex>
                      </Flex>
                      {b.plans.length > 0 && <Table w="100%" data={b.plans} columns={columns} />}
                    </Flex>
                  )
                })}
              </>
            )}
          </Flex>
        )}
      </Flex>

      <ChangeDueDateModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false)
          setSubscriptionId(null)
        }}
        onClick={(dueDate) => onChangeDueDate(subscriptionId, { due_date: dueDate })}
      />
    </Layout>
  )
}

UsersManagement.path = '/internal-adm/users-management'
UsersManagement.title = 'Users adm'
UsersManagement.secured = false

const currentPrefix = 'v3'

export const BARBERSHOP_ADD_CLIENT = `${currentPrefix}/barbershop/add-client/web_barbershop`

export const BARBERSHOP_ADD_BARBER = `${currentPrefix}/barbershop/add-barber/web_barbershop`
export const BARBERSHOP_DELETE_BARBER = (id, type, barbershop_id) =>
  `${currentPrefix}/barbershop/team/delete-barber/${id}?type=${type}&barbershop_id=${barbershop_id}`

export const ASSOCIATE_BARBERSHOP_AND_BARBER = `${currentPrefix}/barbershop/associate-barbershop-and-barber`

export const ADD_SERVICES_APPOINTMENT = `${currentPrefix}/appointment/add-service`
export const GET_SERVICES_APPOINTMENT = (appointment_id) => `${currentPrefix}/appointment/services/${appointment_id}`
export const DELETE_SERVICES_APPOINTMENT = (id) => `${currentPrefix}/appointment/services/${id}`

export const BARBERSHOP_ADD_PAYMENT_METHOD = `${currentPrefix}/barbershop/add-payment-method`
export const BARBERSHOP_UPDATE_PAYMENT_METHOD = (id) => `${currentPrefix}/barbershop/update-payment-method/${id}`
export const BARBERSHOP_DELETE_PAYMENT_METHOD = (id) => `${currentPrefix}/barbershop/delete-payment-method/${id}`
export const BARBERSHOP_GET_PAYMENT_METHOD = (id) => `${currentPrefix}/barbershop/payment-method/${id}`

export const MAIN_DATA = (barbershop_id) => `${currentPrefix}/barbershop/main-data?barbershop_id=${barbershop_id}`
export const ADDRESS_BARBERSHOP = (barbershop_id) =>
  `${currentPrefix}/barbershop/address?barbershop_id=${barbershop_id}`
export const BARBERSHOP_OPENING_HOURS = (barbershop_id) =>
  `${currentPrefix}/barbershop/opening-hours?barbershop_id=${barbershop_id}`
export const BARBERSHOP_PERSONALIZED_HOUR = `${currentPrefix}/barbershop/personalized-opening-hours`
export const BARBERSHOP_AGENDA_INTERVAL = (barbershop_id) =>
  `${currentPrefix}/barbershop/agenda-time-interval?barbershop_id=${barbershop_id}`
export const BARBERSHOP_UPLOAD_IMAGES = (barbershop_id) =>
  `${currentPrefix}/barbershop/upload-images?barbershop_id=${barbershop_id}` // post
export const BARBERSHOP_UPLOAD_IMAGES_EDIT = (id, barbershop_id) =>
  `${currentPrefix}/barbershop/upload-images/${id}?barbershop_id=${barbershop_id}` // delete, put
export const BARBERSHOP_IMAGES = (barbershop_id) => `${currentPrefix}/barbershop/images?barbershop_id=${barbershop_id}`

export const OBSERVATION_CREATE = `${currentPrefix}/barbershop-client-observations`
export const OBSERVATION_GET = (barbershop_id, client_id) =>
  `${currentPrefix}/barbershop-client-observations/${barbershop_id}/${client_id}`
export const OBSERVATION_DELETE = (obs_id) => `${currentPrefix}/barbershop-client-observations/${obs_id}`

export const SERVICES = (id, barber_id) => `${currentPrefix}/barbershop/services/${id}` // get
export const EDIT_SERVICE = (id, barbershop_id) =>
  `${currentPrefix}/barbershop/services/${id}?barbershop_id=${barbershop_id}` // put, delete
export const CREATE_SERVICE = `${currentPrefix}/barbershop/services` // post
export const SERVICES_CATEGORIES = `${currentPrefix}/services-categories` // get, post
export const BARBERS = (id) => `${currentPrefix}/barbershop/team/barbers/${id}` // get
export const ALL_TEAM = (id) => `${currentPrefix}/barbershop/team/all-collaborators/${id}` // get
export const GET_APPOINTMENTS_TO_CLEARED = (data) =>
  `${currentPrefix}/barbershop/finances/commission-paid/${data?.barber_id}/${data?.employee_id}?start_date=${data?.start_date}&end_date=${data?.end_date}&barbershop_id=${data?.barbershop_id}&signature=${data?.signature}` // get
export const GET_APPOINTMENTS_TO_CLEARED_SIGNATURE = (data) =>
  `${currentPrefix}/barbershop/finances/signature/appointments/${data?.barber_id}?start_date=${data?.start_date}&end_date=${data?.end_date}&barbershop_id=${data?.barbershop_id}&signature=${data?.signature}`
export const PAID_APPOINTMENTS_BARBER = `${currentPrefix}/barbershop/finances/paid-barber` // post
export const PAID_APPOINTMENTS_COLLABORATOR = `${currentPrefix}/barbershop/finances/paid-collaborator` // post
export const CREATE_WORTH_BARBER = `${currentPrefix}/barbershop/finances/worth-barber` // post
export const CREATE_CLIENT_RELEASE = `${currentPrefix}/client/release` // post
export const CHANGE_BARBER_VISIBLE = `${currentPrefix}/barbershop/team/visible-barber` // put
export const CHANGE_PLAN_VISIBLE = `${currentPrefix}/barbershop/visible-plan` // put
export const CHANGE_MINIMUM_TIME = `${currentPrefix}/barbershop/personalize/minimum-cancel-time` // put
export const CHANGE_SCHEDULE_TIME = `${currentPrefix}/barbershop/personalize/minimum-schedule-time` // put
export const CHANGE_CONFIG_AVAILABLE = `${currentPrefix}/barbershop/personalize/config-available` // put
export const CHANGE_MAXIMUM_SCHEDULE = `${currentPrefix}/barbershop/personalize/maximum-schedule-date` // put
export const ONLY_BARBERS = (id) => `${currentPrefix}/barbershop/team/only-barbers/${id}` // get
export const UPDATE_PROFILE_IMAGE = (type, barbershop_id, id) =>
  `${currentPrefix}/update-profile-image/${type}?barbershop_id=${barbershop_id}&id=${id}`
export const BARBERSHOP_AVATAR = `${currentPrefix}/profile-image/barbershop`
export const BARBERSHOP_STATUS = (barbershop_id) => `${currentPrefix}/barbershop/status?barbershop_id=${barbershop_id}`
export const UPDATE_BARBERSHOP_STATUS = (status, barbershop_id) =>
  `${currentPrefix}/barbershop/status/${status}?barbershop_id=${barbershop_id}`
export const BARBERSHOP_SUBSCRIPTION = `${currentPrefix}/barbershop/subscription`
export const ACCESS_DATA = `${currentPrefix}/barbershop/access-data` // get and put
export const BARBERSHOP_LINK = (barbershop_id) => `${currentPrefix}/barbershop/slug?barbershop_id=${barbershop_id}`
export const BLOCK_TIMES = (type, barbershop_id) =>
  `${currentPrefix}/barbershop/blocked-times?type=${type}&barbershop_id=${barbershop_id}` // get, post
export const BLOCK_TIMES_BY_PERIOD = `${currentPrefix}/barbershop/blocked-times-by-period` // get, post
export const BLOCK_TIME_DELETE = (id) => `${currentPrefix}/barbershop/blocked-time/${id}` // delete
export const BLOCK_TIME_EXCEPTION = (id) => `${currentPrefix}/barbershop/blocked-times/create-exception/${id}` // put

export const GET_SUBSCRIPTION = `${currentPrefix}/iugu/subscription-detail` // get
export const UPDATE_PASSWORD = `${currentPrefix}/user/password` // put
export const GET_SUBSCRIPTION_DETAIL = `${currentPrefix}/barbershop/subscription/details`

export const BARBERSHOP_MONTH_RESUME = (data) =>
  `${currentPrefix}/barbershop/finances/resume/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
export const BARBERSHOP_EXTRACT = (data) =>
  `${currentPrefix}/barbershop/finances/extract-stock-2/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
export const BARBERSHOP_EXTRACT_UNITYS = (data) =>
  `${currentPrefix}/barbershop/finances/extract-all-unitys-stock/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
export const BARBERSHOP_CLIENTS_RESUME = (data) =>
  `${currentPrefix}/barbershop/finances/clients/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
export const BARBERSHOP_SERVICES_RESUME = (data) =>
  `${currentPrefix}/barbershop/finances/services/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
export const BARBERSHOP_PAYMENTS_RESUME = (data) =>
  `${currentPrefix}/barbershop/finances/payments/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`
export const BARBER_EXTRACT = (data) =>
  `${currentPrefix}/barbershop/finances/barbers-ref/${data.id}?start_date=${data.start_date}&end_date=${data.end_date}&type=${data?.type}`
export const EXPORT_BARBER_EXTRACT = (data) => `${currentPrefix}/barbershop/finances/barbers/export/${data.id}`
export const EXPORT_SIGNATURES_REPORT = (data) =>
  `${currentPrefix}/barbershop/finances/signatures-total-excel/${data.id}`
export const EXPORT_SERVICES_EXTRACT = (data) => `${currentPrefix}/barbershop/finances/services/export/${data.id}`
// export const CREATE_ADDITIONAL_PRODUCTS = `${currentPrefix}/barbershop/additional-products/create`
export const CREATE_ADDITIONAL_PRODUCTS = `${currentPrefix}/barbershop/additional-products/create-stock`
export const UPDATE_ADDITIONAL_PRODUCTS = `${currentPrefix}/barbershop/additional-products/update`
export const DELETE_ADDITIONAL_PRODUCTS = (product_id) =>
  `${currentPrefix}/barbershop/additional-products/delete/${product_id}`
export const GET_ADDITIONAL_PRODUCTS = (barbershop_id) =>
  `${currentPrefix}/barbershop/additional-products/${barbershop_id}`
export const GET_ADDITIONAL_PRODUCTS_PRODUCERS = (barbershop_id) =>
  `${currentPrefix}/barbershop/additional-products-producer/${barbershop_id}`
export const ADD_ADDITIONAL_PRODUCT_APPOINTMENT = `${currentPrefix}/appointment/add-additional-product`
export const REMOVE_ADDITIONAL_PRODUCT_APPOINTMENT = (table_id) =>
  `${currentPrefix}/appointment/remove-additional-product/${table_id}`
export const GET_ADD_ADDITIONAL_PRODUCTS_APPOINTMENT = (appointment_id) =>
  `${currentPrefix}/appointment/get-additional-products/${appointment_id}`

export const ADD_SERVICE_APPOINTMENT = `${currentPrefix}/appointment/add-service`
export const REMOVE_SERVICE_APPOINTMENT = (table_id) => `${currentPrefix}/appointment/remove-service/${table_id}`
export const GET_SERVICE_APPOINTMENT = (appointment_id) => `${currentPrefix}/appointment/get-services/${appointment_id}`
export const REOPEN_APPOINTMENT = (appointment_id) =>
  `${currentPrefix}/appointment/reopen-appointment/${appointment_id}`

export const UPDATE_APPOINTMENT_STATUS = (appointment_id, status) =>
  `${currentPrefix}/appointment/update-status/${appointment_id}/${status}`

export const FINISH_APPOINTMENT = (appointment_id) => `${currentPrefix}/appointment/finish/${appointment_id}`

export const FINISH_REOPENED_APPOINTMENT = (appointment_id) =>
  `${currentPrefix}/appointment/finish-reopened/${appointment_id}`

export const DELETE_APPOINTMENT = (id) => `${currentPrefix}/appointments/delete/${id}`

export const FINANCE_CATEGORY = `${currentPrefix}/barbershop/finances-category` //get, post
export const CREATE_FINANCE_RELEASE = `${currentPrefix}/barbershop/finance-release`
export const CHANGE_FINANCE_RELEASE = (id, barbershop_id) =>
  `${currentPrefix}/barbershop/finance-release/${id}?barbershop_id=${barbershop_id}`
export const BARBERS_EDIT_COMISSION = `${currentPrefix}/barbershop/team/barber-comission` // put

export const GET_APPOINTMENTS_BY_DATE = (date, blockedTimes, barbershop_id, appointmentType) =>
  `${currentPrefix}/barbershop/appointments?date=${date}&blocked=${blockedTimes}&barbershop_id=${barbershop_id}&appointmentType=${appointmentType}`
export const GET_APPOINTMENTS_BY_DATE_HISTORIC = (date, blockedTimes, barbershop_id, appointmentType) =>
  `${currentPrefix}/barbershop/appointments-historic?date=${date}&blocked=${blockedTimes}&barbershop_id=${barbershop_id}&appointmentType=${appointmentType}`
export const CANCEL_RECURRENT_APPOINTMENT = (id) => `${currentPrefix}/appointments/recurrent/cancel/${id}`
export const DELETE_RECURRENT = (id) => `${currentPrefix}/appointments/recurrent/delete/${id}`
export const CONFIRM_RECURRENT = `${currentPrefix}/appointments/recurrent/confirm`
export const GET_CLIENT_DETAIL = (barbershop_id, client_id) =>
  `${currentPrefix}/barbershop/client-detail-ref/${barbershop_id}/${client_id}`
export const GET_CLIENT_APPOINTMENTS = (data) =>
  `${currentPrefix}/barbershop/client-appointments-ref/${data?.barbershop_id}/${data?.client_id}`
export const DELETE_CLIENT = (id, barbershop_id) =>
  `${currentPrefix}/barbershop/clients/${id}?barbershop_id=${barbershop_id}`

export const USER_DATA = (user_id, type) =>
  `${currentPrefix}/user-data?user_id=${user_id || undefined}${type ? `&type=${type}` : ''}`

// SUBSCRIPTION
export const IUGU_CLIENT_CREATE_CREDIT_CARD = `${currentPrefix}/barbershop/subscription/create-credit-card` // POST

export const UNITYS = `${currentPrefix}/barbershop/unitys` // put
export const CREATE_UNITY = `${currentPrefix}/register/new-unity`
export const SEND_NOTIFICAITON = (barbershop_id) =>
  `${currentPrefix}/barbershop/send-notification?barbershop_id=${barbershop_id}`

// SINGLE PRODUCT
export const SINGLE_PRODUCT_POST = `${currentPrefix}/appointments/single-product`
export const SINGLE_PRODUCT_DELETE = (id) => `${currentPrefix}/appointments/single-product/${id}`
export const SINGLE_PRODUCT_GET = (id) => `${currentPrefix}/appointments/single-product/${id}`

// LANDING PAGE
export const LP_FIRST_REGISTER = (campaign) =>
  `${currentPrefix}/lp/${campaign ? 'consumer-day/' : ''}register/first-step` // POST
export const LP_COMPLETE_DATA = (campaign) =>
  `${currentPrefix}/lp/${campaign ? 'consumer-day/' : ''}register/complete-data` // POST
export const LP_CREDIT_CARD = (campaign) => `${currentPrefix}/lp/${campaign ? 'consumer-day/' : ''}register/credit-card` // POST
export const EMPLOYEE_PERMISSION = (employee_id, type) =>
  `${currentPrefix}/barbershop/employee-permission?employee_id=${employee_id}&type=${type}` // delete
export const BARBERSHOP_NOTIFICATION = (barbershop_id) =>
  `${currentPrefix}/barbershop/manager-notification?barbershop_id=${barbershop_id}`

// onboarding
export const FINISH_ONBOARDING = `${currentPrefix}/barbershop/finish-onboarding`

// BARBER PRODUCTS AND SERVICES
export const BARBER_PRODUCTS_AND_SERVICES = `${currentPrefix}/barbershop/barber/products-and-services` // POST

export const SERVICES_BY_BARBER = (barbershop_id, barber_id) =>
  `${currentPrefix}/barbershop/${barbershop_id}/barber/${barber_id}/services?all_services=true` // GET

export const PRODUCTS_BY_BARBER = (barbershop_id, barber_id, type) =>
  `${currentPrefix}/barbershop/${barbershop_id}/barber/${barber_id}/products?type=${type}` // GET

export const GET_AVAILABLE_TIME = `${currentPrefix}/appointment/available-times?type=barbershop`
export const CREATE_APPOINTMENT = `${currentPrefix}/appointments/new-appointment`
export const CREATE_PACKAGE_SERVICE = `${currentPrefix}/appointments/new-package-service`

export const CREATE_RECURRENT_APPOINTMENT = `${currentPrefix}/appointments-recurrents/create`
export const GET_RECURRENT_APPOINTMENTS = (barbershop_id) =>
  `${currentPrefix}/barbershop/appointments-recurrents/${barbershop_id}`

export const GET_FUTURE_APPOINTMENTS = (barbershop_id, service_id, barber_id) =>
  `${currentPrefix}/appointments/future/${barbershop_id}/${service_id}?barber_id=${barber_id || 'undefined'}`

export const UPDATE_APPOINTMENT = `${currentPrefix}/appointments/update-appointment`
export const UPDATE_PRODUCT = `${currentPrefix}/appointment/change-additional-product`
export const UPDATE_CLIENT_PASSWORD = `${currentPrefix}/barbershop/client/change-password`

export const BARBERSHOP_CLIENTS = (props) =>
  `${currentPrefix}/barbershop/${props?.id}/clients/paginated?skip=${props?.skip || 0}&name=${props?.query || ''}`
export const BARBERSHOP_CLIENTS_FREQUENCY = (props) => `${currentPrefix}/barbershop/clients/frequency/${props?.id}`
export const BARBERSHOP_CLIENTS_FREQUENCY_EXCEL = (props) =>
  `${currentPrefix}/barbershop/clients/frequency/${props?.id}`
export const REVIEWS = (barbershop_id) => `${currentPrefix}/barbershop/reviews?barbershop_id=${barbershop_id}` //GET
export const CLIENTS_PACKAGE = (barbershop_id) => `${currentPrefix}/barbershop/package-services/${barbershop_id}` //GET
export const UPDATE_APPOINTMENT_ID = (appointment_id) => `${currentPrefix}/appointment/update-id/${appointment_id}` //GET
// CASHIER - FINANCES
export const GET_CASHIER_TRANSACTIONS_EXCEL = (barbershop_id, date, export_sheet, type) =>
  `${currentPrefix}/barbershop/cashier-export/${barbershop_id}`
export const GET_CASHIER_TRANSACTIONS = (barbershop_id, date, export_sheet, type) =>
  `${currentPrefix}/barbershop/cashier/${barbershop_id}?date=${date}&export=${export_sheet || 'undefined'}&type=${
    type || 'general'
  }`
export const UPDATE_CASHIER_STATUS = (barbershop_id) => `${currentPrefix}/barbershop/cashier/status/${barbershop_id}`
export const NEW_CASHIER_TRANSACTION = `${currentPrefix}/barbershop/cashier/new-transaction`
export const GET_CASHIER_USER_INFO = (barbershop_id) =>
  `${currentPrefix}/barbershop/cashier/get-user-info/${barbershop_id}`

// STOCK
export const CREATE_STOCK = `${currentPrefix}/barbershop/stock`
export const GET_STOCK = (barbershop_id) => `${currentPrefix}/barbershop/stock?barbershop_id=${barbershop_id}`
export const UPDATE_STOCK = (id) => `${currentPrefix}/barbershop/stock/${id}`
export const DELETE_STOCK = (id, product_id) => `${currentPrefix}/barbershop/stock/${id}?product_id=${product_id}`
export const MANAGER_SUBSCRIPTION = (barbershop_id) =>
  `${currentPrefix}/barbershop/manager-subscribe?barbershop_id=${barbershop_id}`
export const CREATE_SUBSCRIPTION = (barbershop_id) =>
  `${currentPrefix}/client/create-subscription?barbershop_id=${barbershop_id}`
export const GET_SUBSCRIPTION_BARBERSHOP = (barbershop_id, client_id, signature_id) =>
  `${currentPrefix}/client/get-subscription?barbershop_id=${barbershop_id}&subscription_id=${signature_id}&client_id=${client_id}`
export const GET_SUBSCRIPTION_CLIENT = (barbershop_id, client_id) =>
  `${currentPrefix}/client/get-subscription?barbershop_id=${barbershop_id}&client_id=${client_id}`
export const GET_SUBSCRIPTION_DETAILS = (barbershop_id, client_id) =>
  `${currentPrefix}/client/subscription-details?barbershop_id=${barbershop_id}&client_id=${client_id}`
export const GET_BALANCE = (barbershop_id) => `${currentPrefix}/barbershop/balance?barbershop_id=${barbershop_id}`
export const GET_ASAAS_EXTRACT = (barbershop_id) =>
  `${currentPrefix}/barbershop/asaas-extract?barbershop_id=${barbershop_id}`
export const REQUEST_ANTECIPATION = `${currentPrefix}/barbershop/antecipation`
export const DELETE_SIGNATURE = (id) => `${currentPrefix}/client/delete-signature?id=${id}`
export const UPDATE_SIGNATURE = (barbershop_id, client_id, type) =>
  `${currentPrefix}/client/update-subscription?barbershop_id=${barbershop_id}&client_id=${client_id}&type=barbershop`
export const GET_BARBERSHOP_SUBSCRIPTION = (barbershop_id, status) =>
  `${currentPrefix}/barbershop/get-signatures?barbershop_id=${barbershop_id}&status=${status}`
export const EXCHANGE_VALUE = (barbershop_id) => `${currentPrefix}/barbershop/exchange?barbershop_id=${barbershop_id}`
export const GET_PAYMENT_APP_CONFIG = (barbershop_id) =>
  `${currentPrefix}/barbershop/payment-on-app?barbershop_id=${barbershop_id}`
export const CHANGE_PAYMENT_APP_CONFIG = (barbershop_id) =>
  `${currentPrefix}/barbershop/payment-on-app?barbershop_id=${barbershop_id}`
export const CREATE_APPOINTMENT_NEW = (payment_form) =>
  `${currentPrefix}/appointments/new-appointment?payment_form=${payment_form || null}`
export const GET_BARBERSHOP_DATA = (barbershop_id) =>
  `${currentPrefix}/barbershop/get-data?barbershop_id=${barbershop_id}`
export const GET_SIGNATURE_FINANCES = (data) =>
  `${currentPrefix}/barbershop/finances/signatures/${data.id}?start_date=${data.start_date}&end_date=${data.end_date}`
export const GET_SIGNATURE_RESUME_FINANCES = (data) =>
  `${currentPrefix}/barbershop/finances/signatures-total/${data.id}?start_date=${data.start_date}&end_date=${data.end_date}&type=${data.type}`
export const UPDATE_APPOINTMENT_HOUR_AND_BARBER = (appointment_id) =>
  `${currentPrefix}/appointment/update-hour-and-barber/${appointment_id}`

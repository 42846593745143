import React, { useMemo, useState } from 'react'
import { Box, Button, CheckBox, Flex, Icon, Text, TextInput } from 'components/atoms'
import { useTheme } from '@chakra-ui/react'
import { useAppointmentDetailStore, useSingleSaleStore, useAppointmentStore } from 'services/stores'
import { t } from 'services/translation'
import { data, validation, initial } from './settings'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { Form } from 'components/molecules'
import { clearCurrency } from 'utils/clearCurrency'
import { ModalButtonBackground } from 'components-v2/atoms'

export const Discount = ({ goBack, isSingleSale, hideDiscountFromBarber, setEnabled }) => {
  const theme = useTheme()
  const { setDiscount, discount, selectedAppointment, setSelectedAppointment, normal, setNormal } =
    useAppointmentDetailStore()
  const { additionalProductsAppointmentList } = additionalProductsStore()
  const { productsList } = useAppointmentStore()
  const { setDiscount: setDiscountSingleSale, products } = useSingleSaleStore()
  const [values, getValues] = useState({ params: initial, isValid: false })
  const [select, setSelect] = useState(true)

  const handleSubmitDiscount = async () => {
    if (normal) {
      const price = values?.params?.discount?.replace(/\,/g, '.')

      if (isSingleSale) {
        setDiscountSingleSale({
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        })
      } else {
        if (setEnabled) {
          setEnabled(false)
        }
        setDiscount({
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        })
      }

      if (selectedAppointment?.status === 'reopened') {
        setSelectedAppointment({
          ...selectedAppointment,
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        })
      }
    }
    if (setEnabled) {
      setEnabled(false)
    }
    goBack()
  }

  const newArr = useMemo(() => {
    const arr = [...additionalProductsAppointmentList]

    if (isSingleSale) {
      arr.push(...products)
    }

    if (selectedAppointment?.formatted_services) {
      arr.push(...selectedAppointment?.formatted_services)
    }
    return arr
  }, [productsList, additionalProductsAppointmentList, selectedAppointment])

  const onChangeValue = (id, key, value) => {
    const changed = discount?.map((item) => {
      if (item.appointment_service_id === id || item?.id === id) {
        return {
          ...item,
          [key]: value,
        }
      }
      return item
    })
    if (isSingleSale) {
      setDiscountSingleSale(changed)
    }
    setDiscount(changed)
  }

  const isDisabled = useMemo(() => {
    if (normal) {
      return (
        !values.params?.discount_observation ||
        values.params?.discount_observation?.length < 3 ||
        !values.params?.discount ||
        values.params?.discount?.length === 0
      )
    }
    return (
      discount?.filter((i) => i?.discount_value).reduce((acc, val) => acc + Number(val?.discount_value) || 0, 0) === 0
    )
  }, [normal, discount, values])
  return (
    <Flex flexDir="column">
      {isSingleSale && (
        <Icon
          name="ArrowLeft"
          color="primary"
          cursor="pointer"
          w="fit-content"
          size={20}
          mb={theme.pxToRem(16)}
          mr={theme.pxToRem(8)}
          onClick={goBack}
        />
      )}
      <Flex alignItems="center" marginBottom="36px">
        <Button
          flex="1"
          mr={theme.pxToRem(8)}
          h={theme.pxToRem(52)}
          kind={normal ? 'primary' : 'outline'}
          onClick={() => {
            setDiscount(null)
            setNormal(true)
          }}
        >
          Descontar todos de uma vez
        </Button>
        {selectedAppointment?.status !== 'reopened' && (
          <Button
            flex="1"
            ml={theme.pxToRem(8)}
            onClick={() => {
              setDiscount(newArr)
              setNormal(false)
            }}
            kind={!normal ? 'primary' : 'outline'}
          >
            Desconto individual no produto/serviço
          </Button>
        )}
      </Flex>
      {!normal ? (
        <>
          <Flex justify="space-between">
            <Text kind="semiBold" w="80%">
              Valor do desconto
            </Text>
            {!hideDiscountFromBarber && (
              <Text kind="semiBold" w="20%">
                Descontar
                <Text fontSize={12} kind="regular">
                  do barbeiro?
                </Text>
              </Text>
            )}
          </Flex>
          <Box marginBottom="16px">
            {newArr?.map((item, index) => (
              <Flex w="100%" justify="space-between" mb="36px" align="center">
                <TextInput
                  mask="CURRENCY_MASK"
                  value={discount?.[index]?.discount_value}
                  label={item?.name}
                  placeholder="Digite o valor"
                  w={hideDiscountFromBarber ? '100%' : '75%'}
                  onChange={(e) => {
                    onChangeValue(
                      item?.appointment_service_id || item?.id,
                      'discount_value',
                      Number(clearCurrency(e.target.value) || 0)
                    )
                  }}
                />
                {!hideDiscountFromBarber && (
                  <CheckBox
                    onChange={(event) =>
                      onChangeValue(
                        item?.appointment_service_id || item?.id,
                        'discount_from_barber',
                        event?.target?.checked
                      )
                    }
                    isChecked={discount?.[index]?.discount_from_barber}
                    color="primary"
                    borderColor="primary"
                    size="lg"
                    align="center"
                    w="16%"
                    mt="8%"
                  />
                )}
              </Flex>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box marginBottom="16px">
            <Form initialValues={initial} validationSchema={validation} data={data} getValues={getValues} mb={36} />
          </Box>

          {!hideDiscountFromBarber && (
            <>
              <Text fontSize={theme.pxToRem(14)} fontWeight="800" marginBottom={theme.pxToRem(10)}>
                {t('DISCOUNT_BARBER_APPOINTMENT')}
              </Text>
              <Text fontSize={theme.pxToRem(12)} marginBottom={theme.pxToRem(16)} color="textGrey" fontWeight="600">
                {t('DISCOUNT_BARBER_SUBTITLE_APPOINTMENT')}
              </Text>
              <Flex alignItems="center" marginBottom="36px">
                <Button
                  flex="1"
                  mr={theme.pxToRem(8)}
                  h={theme.pxToRem(52)}
                  kind={select === true ? 'primary' : 'outline'}
                  onClick={() => {
                    setSelect(true)
                  }}
                >
                  {t('YES')}
                </Button>
                <Button
                  flex="1"
                  ml={theme.pxToRem(8)}
                  onClick={() => {
                    setSelect(false)
                  }}
                  kind={select === false ? 'primary' : 'outline'}
                >
                  {t('NO')}
                </Button>
              </Flex>
            </>
          )}
        </>
      )}

      <ModalButtonBackground>
        <Button type="submit" size="block" onClick={() => handleSubmitDiscount()} disabled={isDisabled}>
          {t('ADD_DISCOUNT')}
        </Button>
      </ModalButtonBackground>
    </Flex>
  )
}

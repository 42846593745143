import axios from 'axios'
import { getData } from 'services/storage'

// const API_URL = process.env.REACT_APP_API_DEV_URL
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  baseURL: 'https://api.prod.bestbarbers.app',
})

api.interceptors.request.use(
  async (config) => {
    const result = localStorage.getItem('USER')
    const userData = await getData('@BestBarbers:userData')
    const user = await getData('@BestBarbers:user')
    if (userData?.version === 'v4' || user?.type === 'internal') {
      // config.baseURL = 'http://localhost:3333'
      config.baseURL = 'https://api.bestbarbers.app'
    }
    if (userData?.version === 'v5') {
      // config.baseURL = 'http://localhost:3333'
      config.baseURL = 'https://bestbarbers-api-prod-v5.up.railway.app'
      // config.baseURL = 'http://localhost:3333'
      // config.baseURL = 'https://bestbarbers-api-homologation.up.railway.app'
    }
    if (userData?.version === 'v6') {
      config.baseURL = 'https://bestbarbers-api-prod-v6.up.railway.app'
      // config.baseURL = 'http://localhost:3333'
    }
    if (result?.token) {
      config.headers.Authorization = `Bearer ${result?.token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export { api, GOOGLE_MAPS_API_KEY }
